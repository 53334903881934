<template>
  <div
    ref="budget-picker"
    class="budget-picker overflow-y-auto d-flex flex-column align-center justify-center"
    :style="{ width: width+'px', 'max-height': height+'px' }"
  >
    <v-text-field
      v-model="controller.budget.value"
      :loading="controller.budget.loading"
      :disabled="disabled"
      :label="$t(controller.budget.label)"
      :rules="validate()"
      :min="min"
      :hint="hint"
      outlined
      type="number"
      name="budget"
      :prefix="$t('general.budget') + ': ' + getCurrency()"
      class="budget-field mx-8 ma-6 d-flex align-center"
      @input="change"
    />
    <div
      v-if="estimated"
      class="estimates outlined rounded ma-4 d-flex"
    >
      <v-chip 
        outlined
        label
        class="label outlined"
      >
        {{ $t('general.estimates') }}
      </v-chip>
      <div 
        v-for="(item, i) in estimates"
        :key="'estimates-'+i"
        class="item d-flex flex-column text-center pa-4 primary--text"
        :class="[item]"
      >
        <v-icon 
          color="primary"
          class="my-4"
        >{{ icons[i] }}</v-icon>
        <span class="text-h6 mb-2">{{ formatNumber(item.value, item.format) }}</span>
        <span class="text-overline grey--text">{{ $t(item.title) }}</span>
      </div>
    </div>
  </div>
</template>

<style>

.budget-picker .estimates .item:not(:last-child) {
  border-right: 1px solid var(--light-border);
}
.budget-picker .estimates .item {
  min-width: 160px;
}
.budget-picker .estimates .label.outlined {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white !important;
  z-index: 2;
}

</style>

<script>
  import { mdiAccount, mdiImage, mdiClock } from '@mdi/js';
  import { icons, utils } from '@/services';

  export default {
    props: {
      budget: {
        type: Number,
        default: 0
      },
      discount: {
        type: Number,
        default: 0
      },
      price: {
        type: Number,
        default: .12
      },
      max: {
        type: Number,
        default: 0
      },
      min: {
        type: Number,
        default: 0
      },
      estimated: {
        type: Boolean,
        default: true
      },
      loading: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      admin: {
        type: Boolean,
        default: false
      },
      width: {
        type: [String, Number],
        default: 'auto'
      },
      height: {
        type: [String, Number],
        default: '100%'
      },
    },
    
    data: () => ({
      icons: {
        audience: mdiAccount,
        impressions: mdiImage,
        cpm: icons.cpm,
        hours: mdiClock,
      },
      controller: {
        budget: {
          value: 0,
          loading: false,
          label: 'general.budget',
        },
      },
      estimates: {
        impressions: {
          title: 'dashboard.impressions.title',
          value: 0,
          format: '0[.]0 a',
        },
        audience: {
          title: 'dashboard.audience.title',
          value: 0,
          format: '0[.]0 a',
        },
        cpm: {
          title: 'dashboard.cpm.title',
          value: 0,
          format: '$ 0[.]0 a'
        },
        hours: {
          title: 'dashboard.airtime.title',
          value: 0,
          format: '00:00'
        },
      },
    }),

    computed: {
      hint () {
        return this.$t('campaigns.max_budget', { value: this.$numeral(this.max).format('$ 0,0.00') });
      },
    }, 

    watch: {
      budget: {
        immediate: true,
        handler (budget) {
          this.controller.budget.value = budget;
          this.setEstimates(budget);
        }
      }
    },

    methods: {
      getCurrency () {
        const symbol = this.$numeral(0).format('$ ').split(' ')[0];
        return symbol;
      },

      validate () {
        const budget = this.controller.budget.value;
        const min = this.min;
        const max = this.max;
        const rules = [budget<=max || this.$t('campaigns.max_budget', { value: this.$numeral(max).format('$ 0,0.00') }), budget>=min || this.$t('campaigns.min_budget', { value: this.$numeral(min).format('$ 0,0.00') })];
        const valid = _.every(rules, r => r==true);
        this.$emit('validate', valid);
        return rules;
      },

      setEstimates (budget) {
        const impressions = budget / ((1-this.discount) * this.price);
        const audience = impressions * 10;
        const cpm = (budget/audience) * 1000;
        const hours = (impressions * 8);

        this.estimates.impressions.value = impressions;
        this.estimates.audience.value = audience;
        this.estimates.cpm.value = cpm;
        this.estimates.hours.value = hours;
      },

      formatNumber (value, format) {
        const formatted = this.$numeral(value).format(format);
        return value==null ? '-' : formatted.indexOf(':')>=0 && formatted.length>=8 ? this.$numeral(value/60/60).format('0,0[.]0') + ' h' : formatted;
      },

      change () {
        const budget = parseInt(this.controller.budget.value);
        this.setEstimates(budget);
        if (budget!=this.budget&&_.every(this.validate(), r => r==true)) {
          this.update(budget);
        }
      },
      
      update (budget) {
        console.log('budget', budget);
        this.$emit('update', budget);
      },

    },

    mounted () {
    }

  }
</script>